import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function Menu ({ onAboutClick, onTestModeClick, onCopyEndsClick, onImportClick, importText, setImportText }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isImporting, setIsImporting] = useState(false) // Add this line

  function toggleModal () {
    setIsOpen(!isOpen)
  }

  function handleImportClick() {
    setIsOpen(false);
    setIsImporting(true); // Add this line
    onImportClick();
  }

  function handleAboutClick() {
    setIsOpen(false);
    onAboutClick();
  }

  function handleTestModeClick() {
    setIsOpen(false);
    onTestModeClick();
  }

  function handleClearClick () {
    if (!window.confirm('Are you sure you want to clear all data?')) return
    
    window.localStorage.clear()
    window.location.reload()
  }

  return (
    <div>
      <FaBars onClick={toggleModal} className='Menu-button ma3' />
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel='Options Menu'
        className='Modal w-100 tr'
        overlayClassName='Overlay'
      >
        <FaTimes onClick={toggleModal} />
        <ul className='tr'>
          <li className='pa3 ma0 bb b--black-20' onClick={handleAboutClick}>About</li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleTestModeClick}>{`${window.location.hash === '#test' ? 'Disable' : 'Enable'}`} Test Mode</li>
          <li className='pa3 ma0 bb b--black-20' onClick={onCopyEndsClick}>Export Data To Clipboard</li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleImportClick}>Import Data</li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleClearClick}>Clear All Data</li>
        </ul>
      </Modal>
    </div>
  )
}

export default Menu
