function upgradeEnds (ends) {
  return ends.map(end => {
    if (!end.version) v0v1(end)

    return end
  })
}

function v0v1 (end) {
  end.version = 1
  end.scores = end.scores.map(score => {
    return {
      0: { value: 0, display: '0' },
      1: { value: 1, display: '1' },
      2: { value: 2, display: '2' },
      3: { value: 3, display: '3' },
      4: { value: 4, display: '4' },
      5: { value: 5, display: '5' },
      6: { value: 6, display: '6' },
      7: { value: 7, display: '7' },
      8: { value: 8, display: '8' },
      9: { value: 9, display: '9' },
      10: { value: 10, display: '10' }
    }[score]
  })

  return end
}

export default upgradeEnds