import React from 'react'

function ScoringButtons ({ addArrowScore, getScoreColor }) {
  return (
    <>
      <div className='flex justify-around items-center mb2'>
        <button
          className={`w-20 pv3 bl bt bb f6 ${getScoreColor('X')}`}
          onClick={() => addArrowScore({value: 10, display: 'X'})}
        >
          X: 10
        </button>
        <button
          className={`w-30 pv3 bl bt bb f6 ${getScoreColor(10)}`}
          onClick={() => addArrowScore({value: 10, display: '10'})}
        >
          Gold Inner: 10
        </button>
        <button
          className={`w-50 pa3 ba f6 ${getScoreColor(9)}`}
          onClick={() => addArrowScore({value: 9, display: '9'})}
        >
          Gold Outer: 9
        </button>
      </div>
      <div className='flex justify-around items-center mb2'>
        <button
          className={`pa3 bt bl bb f6 ${getScoreColor(8)}`}
          onClick={() => addArrowScore({value: 8, display: '8'})}
        >
          Red Inner: 8
        </button>
        <button
          className={`pa3 ba f6 ${getScoreColor(7)}`}
          onClick={() => addArrowScore({value: 7, display: '7'})}
        >
          Red Outer: 7
        </button>
      </div>
      <div className='flex justify-around items-center mb2'>
        <button
          className={`pa3 bt bl bb f6 ${getScoreColor(6)}`}
          onClick={() => addArrowScore({value: 6, display: '6'})}
        >
          Blue Inner: 6
        </button>
        <button
          className={`pa3 ba f6 ${getScoreColor(5)}`}
          onClick={() => addArrowScore({value: 5, display: '5'})}
        >
          Blue Outer: 5
        </button>
      </div>
      <div className='flex justify-around items-center mb2'>
        <button
          className={`pa3 bt bl bb f6 ${getScoreColor(4)}`}
          onClick={() => addArrowScore({value: 4, display: '4'})}
        >
          Black Inner: 4
        </button>
        <button
          className={`pa3 ba f6 ${getScoreColor(3)}`}
          onClick={() => addArrowScore({value: 3, display: '3'})}
        >
          Black Outer: 3
        </button>
      </div>
      <div className='flex justify-around items-center mb2'>
        <button
          className={`pa3 bt bl bb f6 ${getScoreColor(2)}`}
          onClick={() => addArrowScore({value: 2, display: '2'})}
        >
          White Inner: 2
        </button>
        <button
          className={`pa3 ba f6 ${getScoreColor(1)}`}
          onClick={() => addArrowScore({value: 1, display: '1'})}
        >
          White Outer: 1
        </button>
      </div>
      <div className='flex justify-around items-center mb2'>
        <button
          className={`pa3 bt bl br f6 ${getScoreColor(0)}`}
          onClick={() => addArrowScore({value: 0, display: '0'})}
        >
          Miss: 0
        </button>
      </div>
    </>
  )
}

export default ScoringButtons
