import React from 'react'
import getScoreColor from './GetScoreColor'
import './App.css'

function ScoreTable ({
  ends,
  collapsed,
  onEndSelect,
  filterDistance,
  filterDate,
  filterSimulated
}) {
  const filteredEnds =
    filterDistance === 'All Distances'
      ? ends
      : ends.filter(end => end.distance.toString() === filterDistance)
  const filteredEndsByDate = filterEndsByDate(filteredEnds, filterDate)
  const filteredEndsBySimulated =
    filterSimulated === 'Any'
      ? filteredEndsByDate
      : filteredEndsByDate.filter(
          end => (end.isSimulated ? 'Simulated' : 'Not Simulated') === filterSimulated
        )
  const groupedEnds = groupEndsByDate(filteredEndsBySimulated)

  const sortedDateGroups = Object.entries(groupedEnds).sort(
    (a, b) => new Date(b[0]) - new Date(a[0])
  )

  return (
    <>
      {sortedDateGroups.map(([date, endsForDate]) => {
        const summary = calculateSummary(endsForDate, ends)

        return (
          <div className='' key={date}>
            {!collapsed && <h2>{date}</h2>}
            <table>
              {!collapsed && (
                <thead>
                  <tr>
                    <th className='bn tc pa1'></th>
                    <th className='bn tc pa1'></th>
                    <th className='bn tc pa1'>Score</th>
                    <th className='bn tc pa1 w-25'>Average</th>
                    <th className='bn tc pa1 w-25'>Histogram</th>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                  <td className='bn bn tc pa1'></td>
                  <td className='bn bn tc pa1'>
                    {collapsed ? date : 'Summary'}
                  </td>
                  <td className='bn bn tc pa1'>
                    {summary.totalScore} / {summary.combinedScores.length * 10}
                  </td>
                  <td className='bn bn tc pa1 w-25'>
                    {renderAverageLine(
                      summary.averageScore,
                      summary.standardDeviation
                    )}
                  </td>
                  <td className='bn pa1 w-25'>
                    {renderHistogram(summary.combinedScores)}
                  </td>
                </tr>

                {!collapsed &&
                  endsForDate.map((end, index) => {
                    const {
                      endTotalScore,
                      endAverageScore,
                      endArrows,
                      endIndex
                    } = summary.endSummaries[index]
                    const standardDeviation = calculateStandardDeviation(
                      end.scores
                    )
                    return (
                      <tr key={index} onClick={() => onEndSelect(endIndex)}>
                        <td className='bn tc pa1'>
                          {endsForDate.length - index}
                        </td>
                        <td className='bn tc pa1'>
                          {end.timestamp.toLocaleTimeString([], {
                            hour: 'numeric',
                            minute: '2-digit'
                          })}
                        </td>
                        <td className='bn tc pa1'>
                          {endTotalScore} / {endArrows * 10}
                        </td>
                        <td className='bn tc pa1 w-25'>
                          {renderAverageLine(
                            endAverageScore,
                            standardDeviation
                          )}
                        </td>
                        <td className='bn tc pa1 w-25'>
                          {renderHistogram(end.scores)}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        )
      })}
    </>
  )
}

function calculateStandardDeviation (scores) {
  const average =
    scores.reduce((sum, score) => sum + score.value, 0) / scores.length
  const squaredDifferences = scores.map(score => (score.value - average) ** 2)
  const variance =
    squaredDifferences.reduce(
      (sum, squaredDifference) => sum + squaredDifference,
      0
    ) / squaredDifferences.length
  return Math.sqrt(variance)
}

function renderAverageLine (average, standardDeviation) {
  const percentage = average * 10
  const stdDevPercentage = standardDeviation ? standardDeviation * 10 : 2
  const leftEdgePercentage = Math.max(0, percentage - stdDevPercentage)
  const scoreColor = getScoreColor(Math.round(average))

  return (
    <div
      className='average-line-container bg-light-gray'
      style={{ width: '100%', height: '8px' }}
    >
      <div
        className={`average-line ${getScoreColor(Math.round(average))}`}
        style={{
          width: `${stdDevPercentage}%`,
          height: '8px',
          marginLeft: `${leftEdgePercentage}%`
        }}
      ></div>
      <span className='average-value' style={{ marginLeft: '4px' }}>
        {average.toFixed(2)}
      </span>
    </div>
  )
}

function groupEndsByDate (ends) {
  const groupedEnds = {}

  ends.forEach(end => {
    const dateKey = end.timestamp.toLocaleDateString()

    if (!groupedEnds[dateKey]) {
      groupedEnds[dateKey] = []
    }

    groupedEnds[dateKey].push(end)
  })

  // Sort ends for each date in descending order by time
  for (const dateKey in groupedEnds) {
    groupedEnds[dateKey].sort((a, b) => b.timestamp - a.timestamp)
  }

  return groupedEnds
}

function renderHistogram (scores) {
  const scoreCounts = Array(11).fill(0)
  const totalArrows = scores.length

  scores.forEach(score => {
    scoreCounts[score.value]++
  })

  const scorePercentages = scoreCounts.map(count => (count / totalArrows) * 100)
  const maxPercentage = Math.max(...scorePercentages)

  const normalizedPercentages = scorePercentages.map((percentage, score) => ({
    percentage: (percentage / maxPercentage) * 100,
    score
  }))

  return (
    <div className='flex flex-row-reverse items-center justify-center h2'>
      {normalizedPercentages.reverse().map(({ percentage, score }) => (
        <div
          key={score}
          className={`histogram-bar ${getScoreColor(score)}`}
          style={{ width: '9%', minHeight: 2, height: `${percentage}%` }}
        ></div>
      ))}
    </div>
  )
}

function calculateSummary (endsForDate, ends) {
  const combinedScores = endsForDate.flatMap(end => end.scores)
  const totalScore = combinedScores.reduce((sum, score) => sum + score.value, 0)
  const averageScore = totalScore / combinedScores.length
  const standardDeviation = calculateStandardDeviation(combinedScores)

  // Calculate total score, average score, and arrow count for each end
  const endSummaries = endsForDate.map(end => {
    const endTotalScore = end.scores.reduce(
      (sum, score) => sum + score.value,
      0
    )
    const endAverageScore = endTotalScore / end.scores.length
    const endArrows = end.scores.length
    const endIndex = ends.indexOf(end)
    return { endTotalScore, endAverageScore, endArrows, endIndex }
  })

  return {
    totalScore,
    averageScore,
    standardDeviation,
    combinedScores,
    endSummaries
  }
}

export function filterEndsByDate (ends, filterDate) {
  const now = new Date()
  let dateLimit
  switch (filterDate) {
    case '1 Year':
      dateLimit = new Date(now.setFullYear(now.getFullYear() - 1))
      break
    case '6 months':
      dateLimit = new Date(now.setMonth(now.getMonth() - 6))
      break
    case '3 months':
      dateLimit = new Date(now.setMonth(now.getMonth() - 3))
      break
    case '1 month':
      dateLimit = new Date(now.setMonth(now.getMonth() - 1))
      break
    case '2 weeks':
      dateLimit = new Date(now.setDate(now.getDate() - 14))
      break
    case '1 week':
      dateLimit = new Date(now.setDate(now.getDate() - 7))
      break
    case '3 days':
      dateLimit = new Date(now.setDate(now.getDate() - 3))
      break
    case '1 day':
      dateLimit = new Date(now.setDate(now.getDate() - 1))
      break
    case '12 hours':
      dateLimit = new Date(now.setHours(now.getHours() - 12))
      break
    case '6 hours':
      dateLimit = new Date(now.setHours(now.getHours() - 6))
      break
    case '1 hour':
      dateLimit = new Date(now.setHours(now.getHours() - 1))
      break
    default:
      return ends
  }
  return ends.filter(end => new Date(end.timestamp) >= dateLimit)
}

export default ScoreTable
