import React from 'react';

function About() {
  return (
    <div className="About mb4">
      <h2>🏹 ArcherScore</h2>
      <p>
        Let's help you keep track of your archery scores! Whether you're a beginner or an experienced archer, this app provides an easy way to record, manage, and analyze your performance over time.
      </p>
      <p>
        The app allows you to enter your scores for each end, with support for different distances and target types. As you progress through your practice sessions or competitions, you can easily add, edit, or remove scores, giving you full control over your data.
      </p>
      <p>
        To get started, click the "Score End" button to begin entering your scores for a new end. You can enter the arrow scores one by one, and the app will automatically calculate your total score for the end. Once you've entered all your scores for the end, click the "Finish End" button to save your data.
      </p>
      <p>
        The app also provides a convenient way to view your scores in a table format, with the option to collapse or expand the data by date. This makes it easy to review your performance and identify trends or areas for improvement.
      </p>
      <p>
        To get a better feel for the app, <a className='blue' href='#test'>you can try it out with test data</a>.
      </p>
      <p>
        We hope you find this app useful in tracking your archery progress and achieving your goals. Happy shooting!
      </p>
      
    </div>
  );
}

export default About;