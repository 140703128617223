import React, { useState, useEffect } from 'react'
import { Scatter } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import * as dateAdapter from 'chartjs-adapter-date-fns'
import chartTrendline from 'chartjs-plugin-trendline'
import { filterEndsByDate } from './ScoreTable.js'

Chart.register(chartTrendline, dateAdapter, ...registerables)

function ScoreScatterPlot ({ ends, filterDistance, filterDate, filterSimulated }) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  const filteredEndsByDistance =
    filterDistance === 'All Distances'
      ? ends
      : ends.filter(end => end.distance.toString() === filterDistance)

  const filteredEndsBySimulated =
      filterSimulated === 'Any'
        ? filteredEndsByDistance
        : filteredEndsByDistance.filter(
            end => (end.isSimulated ? 'Simulated' : 'Not Simulated') === filterSimulated
          )

  const filteredEnds = filterEndsByDate(filteredEndsBySimulated, filterDate)

  if (filteredEnds.length < 2) return null

  const timestamps = filteredEnds.map(end => new Date(end.timestamp).getTime())
  const minTimestamp = Math.min(...timestamps)
  const maxTimestamp = Math.max(...timestamps)

  // const padding = 24 * 60 * 60 * 1000 // 1 day in milliseconds
  const minRange = 3600 * 1000
  const range = maxTimestamp - minTimestamp
  const padding = 0.1 * range
  const min = new Date(minTimestamp - padding)
  const max = new Date(maxTimestamp + padding)

  const dataset = {
    label: 'Average Over Time',
    data: filteredEnds.map(end => {
      const totalScore = end.scores.reduce((a, b) => a + b.value, 0)
      const averageScore = totalScore / end.scores.length
      return {
        x: new Date(end.timestamp),
        y: averageScore
      }
    }),
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: 'rgba(75,192,192,1)',
    pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: 'transparent',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 10
  }

  if (range > minRange) {
    dataset.trendlineLinear = {
      colorMin: 'rgba(231, 4, 15, 0.3)',
      colorMax: 'rgba(231, 4, 15, 0.3)',
      lineStyle: 'solid',
      width: 2,
      projection: true
    }
  }

  const data = {
    datasets: [dataset]
  }

  const options = {
    plugins: {
      legend: {
        display: false // This hides all text in the legend and also the labels.
      }
    },
    animation: {
      duration: 0,
      resize: { duration: 0 }
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d'
          }
        },
        min: min,
        max: max
      },
      y: {
        beginAtZero: false,
        max: 10
      }
    }
  }

  return (
    <div className='center w-100-s w-80-ns'>
      <div style={{ opacity: 0, height: 1 }}>{JSON.stringify(windowSize)}</div>
      <Scatter data={data} options={options} redraw={true} />
    </div>
  )
}

export default ScoreScatterPlot
