function getScoreColor (score) {
    return (
      {
        0: 'b--black bg-purple white',
        1: 'b--black bg-black-30 black',
        2: 'b--black bg-black-20 black',
        3: 'b--white bg-black white',
        4: 'b--white bg-black-80 white',
        5: 'b--black bg-dark-blue white',
        6: 'b--black bg-blue white',
        7: 'b--black bg-dark-red white',
        8: 'b--black bg-red white',
        9: 'b--black bg-gold black',
        10: 'b--black bg-yellow black',
        X: 'b--black bg-light-yellow black'
      }[score] || ''
    )
  }

export default getScoreColor;