import React from 'react'

function ScoreDisplay ({ currentEndScores, getScoreColor }) {
  return (
    <div>
      <div className='tc mb4'>{currentEndScores.length ? `${currentEndScores.length} Arrows` : ''}</div>
      <div className='mt1 flex flex-wrap justify-center'>
        {currentEndScores.map((score, index) => (
          <div
            key={index}
            className={`ba pv2 br2 mr1 mb1 w-10 tc ${getScoreColor(score.value)}`}
          >
            {score.display}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScoreDisplay
